
// import './App.css';
// import {Slideshow, Slide, TextoSlide} from './component/SlideShow';
// import './estilos.css';
// import styled from 'styled-components';
import img1 from './img/5.png';
import img2 from './img/6.png';
import img3 from './img/7.png';
import img4 from './img/4.jpg';
import { Slideshow, Slide, TextoSlide } from './SlideShow';

function App() {
  return (
    <div className="App">
      {/* <PinturaPared/> */}

			{/* <Slideshow controles={true}>
				<Slide>
					<a href="https://www.falconmaters.com">
						<img src={img1} alt=""/>
					</a>
					<TextoSlide>
						<p>15% descuento en productos Apple</p>
					</TextoSlide>
				</Slide>
				<Slide>
					<a href="https://www.falconmaters.com">
						<img src={img2} alt=""/>
					</a>
					<TextoSlide>
						<p>15% descuento en productos Apple</p>
					</TextoSlide>
				</Slide>
				<Slide>
					<a href="https://www.falconmaters.com">
						<img src={img3} alt=""/>
					</a>
					<TextoSlide>
						<p>15% descuento en productos Apple</p>
					</TextoSlide>
				</Slide>
				<Slide>
					<a href="https://www.falconmaters.com">
						<img src={img4} alt=""/>
					</a>
					<TextoSlide>
						<p>15% descuento en productos Apple</p>
					</TextoSlide>
				</Slide>
			</Slideshow> */}

			<Slideshow controles={true} autoplay={true} velocidad="3000" intervalo="5000">
				<Slide>
					<a href="https://alexcamachon.com/filtro/Abstracto">
						<img src={img1} alt=""/>
					</a>
					<TextoSlide colorFondo="">
						<p>Galeria de arte Online</p>
					</TextoSlide>
				</Slide>
				<Slide>
					<a href="https://alexcamachon.com/caricatura">
						<img src={img2} alt=""/>
					</a>
					<TextoSlide >
						<p>Galeria de arte Online</p>
					</TextoSlide>
				</Slide>
                <Slide>
					<a href="https://alexcamachon.com/detalle/17">
						<img src={img3} alt=""/>
					</a>
					<TextoSlide >
						<p>Galeria de arte Online</p>
					</TextoSlide>
				</Slide>
			</Slideshow> 
    </div>
  );
}

export default App;