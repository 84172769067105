import React from 'react'
import { useNavigate } from 'react-router-dom'
import galery from '../camachonegro.png'
const Error = () => {
    const volver= useNavigate()
  return (
    <>
    <div style={{margin:'10px', minHeight:'', display:'', justifyContent:'center',  textAlign:'center',}}>
        <p>Gracias! Galeria <b><i>Camacho</i></b> les da la bienvenid@</p>
        <div>
          <button onClick={()=>volver('/promesa') } type="button" class="btn btn-outline-dark">Empezar</button>
        </div>
        
    </div>
    <div style={{minHeight:'30vh',textAlign:'center' }}>
      <img src={galery} alt="" height='150' />
    </div>
    </>
  )
}

export default Error