import React from 'react'

const Store = () => {
  return (
    <div>
        <h3 style={{ margin:'45px', padding:"0px"}}>Lienzos A.Camacho.N</h3>
        <table style={{border:"1px solid rgba(4, 1, 0, 4.05)",margin:'40px', color:"", background:'',width:'80%'}}>
            <tr>
                <th colSpan="5" style={{textAlign:"center",}}>Formatos de lienzos segun generos artísticos</th>
                {/* <th>Precio</th>
                <th>otro</th> */}
            </tr>
            <tr style={{border:"1px solid rgba(4, 1, 0, 4.05)",width:"80px", textAlign:"center"}}>
                <td style={{border:"1px solid rgba(4, 1, 0, 4.05)", }}>Número</td>
                <td style={{border:"1px solid rgba(4, 1, 0, 4.05)", }}>Figura (cm)</td>
                <td style={{border:"1px solid rgba(4, 1, 0, 4.05)",}}>Paisaje (cm)</td>
                <td style={{border:"1px solid rgba(4, 1, 0, 4.05)",}}>Marina (cm)</td>
                <td style={{border:"1px solid rgba(4, 1, 0, 4.05)",}}>Precio</td>
            </tr>
            <tr style={{border:"1px solid rgba(4, 1, 0, 4.05)",textAlign:"center"}}>
                <td style={{border:"1px solid rgba(4, 1, 0, 4.05)",}}>0</td>
                <td style={{border:"1px solid rgba(4, 1, 0, 4.05)",}}>18 x 14</td>
                <td style={{border:"1px solid rgba(4, 1, 0, 4.05)",}}>18 x 12</td>
                <td style={{border:"1px solid rgba(4, 1, 0, 4.05)",}}>18 x 10</td>
            </tr>
            <tr style={{border:"1px solid rgba(4, 1, 0, 4.05)",textAlign:"center"}}>
                <td style={{border:"1px solid rgba(4, 1, 0, 4.05)",}}>1</td>
                <td style={{border:"1px solid rgba(4, 1, 0, 4.05)",}}>22 x 16</td>
                <td style={{border:"1px solid rgba(4, 1, 0, 4.05)",}}>22 x 14</td>
                <td style={{border:"1px solid rgba(4, 1, 0, 4.05)",}}>22 x 12</td>
            </tr>
        </table>
        

    </div>
  )
}

export default Store